import React, { useRef, useState } from "react";
import "./Header.scss";
import { logo, menuClose, selectBgIcon } from "../../common/images";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { scrollToTop } from "../../common/ScrollToTop";
import { scrollToSection } from "../../common/ScrollToSection";
import { config } from "../../config";

const Header = ({ setOverlay }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownServicesRef = useRef(null);
  const [isMblDropdownOpen, setIsMblDropdownOpen] = useState(false);
  const [isMblServicesDropdownOpen, setIsMblServicesDropdownOpen] =
    useState(false);
  const [showMenu, setShowMenu] = useState(false);

  // close navbar dropdown when click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleServicesClickOutside = (event) => {
      if (
        dropdownServicesRef.current &&
        !dropdownServicesRef.current.contains(event.target)
      ) {
        setIsServicesDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleServicesClickOutside);
    return () => {
      document.removeEventListener("click", handleServicesClickOutside);
    };
  }, []);

  // add background color to navbar when scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // toggle services dropdown
  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  // close mobile menu when click on close button
  const closeMblMenu = () => {
    setShowMenu(false);
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      setOverlay(true);
      document.body.classList.add("hide-scroll");
    } else {
      setOverlay(false);
      document.body.classList.remove("hide-scroll");
    }
    setIsMblDropdownOpen(false);
  };

  // close menu
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    setShowMenu(false);
    if (!menuOpen) {
      setOverlay(true);
      document.body.classList.add("hide-scroll");
    } else {
      setOverlay(false);
      document.body.classList.remove("hide-scroll");
      scrollToTop();
    }
    setIsMblDropdownOpen(false);
  };

  // toggle mobile menu dropdown
  const handleMblDropDown = () => {
    setIsMblDropdownOpen(!isMblDropdownOpen);
  };

  // toggle services mobile menu dropdown
  const handleMblServicesDropDown = () => {
    setIsMblServicesDropdownOpen(!isMblServicesDropdownOpen);
  };

  return (
    <>
      <nav className={`${scrolling ? "scroll-color" : ""}`}>
        <div className="container nav-header">
          <div className="logo" data-aos="fade-right">
            <NavLink to="/" onClick={scrollToTop}>
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
          <div className="nav-items">
            <ul>
              <li data-aos="fade-up">
                <NavLink
                  to="/"
                  // activeClassName="selected"
                  onClick={scrollToTop}
                >
                  Home
                </NavLink>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="200"
                data-aos-delay="100"
              >
                <NavLink
                  to="/about"
                  // activeClassName="selected"
                  onClick={scrollToTop}
                >
                  About us
                </NavLink>
              </li>
              <li
                className="custom-nav-dropdown"
                ref={dropdownServicesRef}
                onClick={toggleServicesDropdown}
                data-aos="fade-up"
                data-aos-duration="200"
                data-aos-delay="200"
              >
                <NavLink className="dropdown-toggle">
                  Services
                  <div
                    className={`dropdown-content ${
                      isServicesDropdownOpen ? "open" : ""
                    }`}
                  >
                    <NavLink
                      // activeClassName="selected"
                      to="/services"
                      onClick={() => setIsServicesDropdownOpen(false)}
                    >
                      Banking Services
                    </NavLink>
                    <NavLink
                      // activeClassName="selected"
                      to="/merchant-services"
                      onClick={() => setIsServicesDropdownOpen(false)}
                    >
                      Merchant Services
                    </NavLink>
                    <NavLink
                      // activeClassName="selected"
                      to="/exchange-services"
                      onClick={() => setIsServicesDropdownOpen(false)}
                    >
                      Exchange Services
                    </NavLink>
                  </div>
                </NavLink>
                <img
                  className="dropdown-icon"
                  src={selectBgIcon}
                  alt="dropdown-icon"
                />
              </li>
              <li
                className="custom-nav-dropdown"
                onClick={toggleDropdown}
                ref={dropdownRef}
                data-aos="fade-up"
                data-aos-duration="200"
                data-aos-delay="300"
              >
                <NavLink className="dropdown-toggle">
                  Developers
                  <div
                    className={`dropdown-content ${
                      isDropdownOpen ? "open" : ""
                    }`}
                  >
                    <NavLink
                      // activeClassName="selected"
                      to="/developer"
                      onClick={() => scrollToSection("coreApi", "developer")}
                    >
                      Core Banking API
                    </NavLink>
                    {config.DisplayPORTL ? (
                      <NavLink
                        // activeClassName="selected"
                        to="/developer"
                        onClick={() => scrollToSection("portlApi", "developer")}
                      >
                        PORTL API
                      </NavLink>
                    ) : (
                      <></>
                    )}
                    <NavLink
                      // activeClassName="selected"
                      to="/developer"
                      onClick={() => scrollToSection("monexApi", "developer")}
                    >
                      Monex API
                    </NavLink>
                  </div>
                  <img
                    className="dropdown-icon"
                    src={selectBgIcon}
                    alt="dropdown-icon"
                  />
                </NavLink>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="200"
                data-aos-delay="400"
              >
                <NavLink
                  to="/blog"
                  // activeClassName="selected"
                  onClick={scrollToTop}
                >
                  News
                </NavLink>
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="200"
                data-aos-delay="500"
              >
                <NavLink
                  to="/contact"
                  // activeClassName="selected"
                  onClick={scrollToTop}
                >
                  Support
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="nav-btns" data-aos="fade-left">
            <NavLink className="login" to="/login" onClick={scrollToTop}>
              Log in
            </NavLink>
            <NavLink className="signup" to="/signup" onClick={scrollToTop}>
              Sign Up
            </NavLink>
          </div>
          <div
            className={`nav-item-mobile ${showMenu ? "animate" : ""}`}
            onClick={handleMenuClick}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>

      {/* mbl navigation */}
      <div className={`mbl-menu ${menuOpen ? "toggle-menu" : ""}`}>
        <div className="mbl-nav-items">
          <div className="header">
            <NavLink
              to="/"
              onClick={handleMenuClick}
              className={`header-no-animate ${
                menuOpen ? "header-animate" : ""
              }`}
            >
              <img src={logo} alt="logo" />
            </NavLink>
            <img
              className={`close-animate close-menu ${
                menuOpen ? "close-animate" : ""
              }`}
              src={menuClose}
              alt="close"
              onClick={closeMblMenu}
            />
          </div>
          <ul>
            <li className={`no-animate ${menuOpen ? "animate-item" : ""}`}>
              <NavLink to="/" onClick={handleMenuClick}>
                Home
              </NavLink>
            </li>
            <li className={`no-animate ${menuOpen ? "animate-item" : ""}`}>
              <NavLink to="/about" onClick={handleMenuClick}>
                About us
              </NavLink>
            </li>
            <li
              className={`no-animate mbl-dropdown ${
                menuOpen ? "animate-item" : ""
              }`}
              onClick={handleMblServicesDropDown}
            >
              <NavLink>Services</NavLink>
              <img
                className="dropdown-icon"
                src={selectBgIcon}
                alt="dropdown-icon"
              />
              <ul
                className={`mbl-dropdown-content ${
                  isMblServicesDropdownOpen ? "open" : ""
                }`}
              >
                <li>
                  <NavLink onClick={handleMenuClick} to="/services">
                    Banking Services
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleMenuClick} to="/merchant-services">
                    Merchant Services
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleMenuClick} to="/exchange-services">
                    Exchange Services
                  </NavLink>
                </li>
              </ul>
            </li>
            <li
              className={`no-animate mbl-dropdown ${
                menuOpen ? "animate-item" : ""
              }`}
              onClick={handleMblDropDown}
            >
              <NavLink to="">
                Developers{" "}
                <img
                  className="dropdown-icon"
                  src={selectBgIcon}
                  alt="dropdown-icon"
                />
              </NavLink>
              <ul
                className={`mbl-dropdown-content ${
                  isMblDropdownOpen ? "open" : ""
                }`}
              >
                <li>
                  <NavLink onClick={handleMenuClick} to="/developer#coreApi">
                    Core Banking API
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={handleMenuClick} to="/developer#monexApi">
                    Monex API
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className={`no-animate ${menuOpen ? "animate-item" : ""}`}>
              <NavLink onClick={handleMenuClick} to="/blog">
                News
              </NavLink>
            </li>
            <li className={`no-animate ${menuOpen ? "animate-item" : ""}`}>
              <NavLink onClick={handleMenuClick} to="/contact">
                Support
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="mbl-nav-btns">
          <NavLink
            className={`login-no-animate login ${
              menuOpen ? "login-animate" : ""
            }`}
            to="/login"
            onClick={handleMenuClick}
          >
            Log in
          </NavLink>
          <NavLink
            className={`signup-no-animate signup ${
              menuOpen ? "signup-animate" : ""
            }`}
            to="/signup"
            onClick={handleMenuClick}
          >
            Sign Up
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Header;
